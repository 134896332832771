<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <div class="c_group">
          <XzTagGroup :data="statusWithAll" :active="outform.status"
            @handleClick="(v) => handleOutChange('status', v)">
            <span class="title">询价状态</span>
          </XzTagGroup>
          <span class="c_help">帮助<el-tooltip effect="dark" content="仅体现双方基于小紫【供需通】的线上报价，最终确认价格以双方签订的合同或订单为依据。"
              placement="top-start"><i class="el-icon-question"></i></el-tooltip></span>
        </div>
        <div class="table-wrapper" :class="{ 'fullscreen': isFullScreen }">
          <xz-table ref="xzTable" :columns="columnsLists" :data="data" :searchAttrs="searchAttrs"
            :searchVal.sync="outform.code" :toolsAttrs="toolsAttrs" :drawerVisible.sync="drawerVisible"
            :scroll="{ x: '100%', y: scrollHeight }" :drawerWidth="500" @search="() => handleOutChange()" rowKey="id"
            @refresh="handleReset" @fullScreen="handleFullScreen" class="wrap">
            <template #drawer>
              <div style="flex: 1; overflow: auto" class="advanced-search">
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">
                  <a-form-model-item label="询价状态">
                    <a-select v-model="form.status" placeholder="请选择" allow-clear>
                      <a-select-option value="">全部</a-select-option>
                      <a-select-option value="WAIT_TASK_ORDER">待接单</a-select-option>
                      <a-select-option value="WAIT_QUOTE_PRICE">待报价</a-select-option>
                      <a-select-option value="QUOTED">已报价</a-select-option>
                      <a-select-option value="NEGOTIATING">议价中</a-select-option>
                      <a-select-option value="ENTERED">已入围</a-select-option>
                      <a-select-option value="WINTHEBID">已中标</a-select-option>
                      <a-select-option value="REJECTED">已拒单</a-select-option>
                      <a-select-option value="CLOSED">已关单</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="询单号">
                    <a-input v-model="form.code" placeholder="请输入" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="业务类型">
                    <a-select v-model="form.businessType" placeholder="请选择" allow-clear>
                      <a-select-option value="">全部</a-select-option>
                      <a-select-option value="MAINTAIN">维修</a-select-option>
                      <a-select-option value="SPARE_PART">备件</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="询价单位/个人">
                    <a-input v-model="form.inquirePriceParty" placeholder="请输入" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="工单">
                    <a-input v-model="form.workOrderCode" placeholder="请输入" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="申请人">
                    <a-input v-model="form.createUserName" placeholder="请输入" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="接单人">
                    <a-input v-model="form.taskOrderUserName" placeholder="请输入" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="创建时间">
                    <a-date-picker v-model="form.createTimeStart" placeholder="请选择开始时间" valueFormat="x"
                      style="width: 46%;min-width: 100px;" allow-clear show-time>
                    </a-date-picker>
                    -
                    <a-date-picker v-model="form.createTimeEnd" placeholder="请选择结束时间" valueFormat="x"
                      style="width: 46%;min-width: 100px;" allow-clear show-time>
                    </a-date-picker>
                  </a-form-model-item>
                  <a-form-model-item label="紧急程度">
                    <a-select v-model="form.emergencyLevel" placeholder="请选择" allow-clear>
                      <a-select-option value="">全部</a-select-option>
                      <a-select-option value="HIGH">高</a-select-option>
                      <a-select-option value="MEDIUM">中</a-select-option>
                      <a-select-option value="LOW">低</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div style="text-align: right; height: 68px; line-height: 68px">
                <a-button @click="handleEmpty">
                  重置
                </a-button>
                <a-button type="primary" style="margin-left: 10px;" @click="handleAdvancedQuery">
                  确定
                </a-button>
              </div>
            </template>
            <template #custom>
              <el-popover placement="left" width="300" :tabindex="10000" popperClass="JZPopper" v-model="showAttribute"
                trigger="manual">
                <div class="top"><span>字段设置</span><time @click="showAttribute = false">&times;</time>
                </div>
                <draggable v-model="lockLists" draggable=".list-item">
                  <template v-if="lockLists && lockLists.length">
                    <li v-for="(item, index) in lockLists" :key="item.fieldName" class="list-item">
                      <el-checkbox v-model="item.isShow" :disabled="item.isDisabled"
                        style="margin-top:6px;"></el-checkbox>
                      <div class="labelContainer">{{ item.fieldName }}</div>
                      <i :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                        style="font-size: 12px;margin-top:10px;margin-left:12px;"
                        @click="handleClickLock(item, index)"></i>
                      <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                    </li>
                  </template>
                  <template v-else>
                    <li class="noFixedItem">
                      锁定下列项目到此固定
                    </li>
                  </template>
                </draggable>
                <h3 style="border-top: 1px solid #DFE2E8;font-size:14px;height:40px;line-height:40px;margin-bottom:0;">
                  不固定</h3>
                <draggable v-model="attrLists" draggable=".list-item">
                  <li v-for="(item, index) in attrLists" :key="item.fieldName" class="list-item">
                    <el-checkbox v-model="item.isShow" :disabled="item.isDisabled"
                      style="margin-top:6px;"></el-checkbox>
                    <div class="labelContainer">{{ item.fieldName }}</div>
                    <i :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                      style="font-size: 12px;margin-top:10px;margin-left:12px;"
                      @click="handleClickLock(item, index)"></i>
                    <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                  </li>
                </draggable>
                <div class="text-right">
                  <el-button type="default" size="mini" @click="handleResetCancel">重置</el-button>
                  <el-button type="primary" size="mini" @click="handleSave">保存</el-button>
                </div>
                <el-button slot="reference" @click='handleSort'
                  style='width:32px;height:32px;margin-left:8px;padding: 8px;'><img
                    :src="require('@/assets/ic_setting.svg')"
                    style='width:16px;height:16px;font-size:16px;' /></el-button>
              </el-popover>
            </template>
            <span slot="inquireTenantStatusDesc" slot-scope="text,record">
              <span v-if="record.inquireTenantStatusDesc" :class="['c_tag', record.inquireTenantStatus ? record.inquireTenantStatus.toLowerCase() : '']">{{
                text
              }}</span>
              <el-tooltip v-if="record.inquireTenantStatus === 'NEGOTIATING' && record.negotiatingType === 'ROLLBACK'" effect="dark" :content="`回退原因：${record.rollbackReason}`"
                placement="top-start"><i
                  :class="['el-icon-warning-outline', record.inquireTenantStatus ? record.inquireTenantStatus.toLowerCase() : '']"></i></el-tooltip>
                  <el-tooltip v-if="record.inquireTenantStatus === 'NEGOTIATING' && record.negotiatingType === 'UPDATE'" effect="dark" :content="`修改报价：询单业务员已修改报价，请确认报价。如有疑问请联系${ record.createUserName }/${ record.createUserPhone }`"
                placement="top-start"><i
                  :class="['el-icon-warning-outline', record.inquireTenantStatus ? record.inquireTenantStatus.toLowerCase() : '']"></i></el-tooltip>
            </span>
            <span slot="code" slot-scope="text,record">
              <span :class="[detailPermission ? 'code' : '']" @click="() => handleView(record)">{{ text }}</span>
            </span>
            <span v-if="record.hasPermissionDealFlag" slot="action" slot-scope="_, record">
                <a @click="() => handleInquiryform(record)">处理</a>
            </span>
          </xz-table>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper">
      <a-pagination :current="page.pageNum" :total="total" :show-total="total => `共 ${total} 条`" show-size-changer
        show-quick-jumper :pageSize.sync="page.pageSize" @change="handleNumChange" @showSizeChange="handleSizeChange" />
    </div>
    <DetailDialog ref="DetailDialogRef" v-if="dialogVisible" :dialog-visible.sync="dialogVisible" :id="currentRow.id" :type="currentRow.businessType"></DetailDialog>
  </div>
</template>

<script>
import { inquirePricePageList, inquirePriceCount } from '@/api/inquiryForm.js';
import { getFiledRuleLists, addFiledRule } from '@/api/contract';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import XzTagGroup from '../../../components/xiaozi-ui/xz-tag-group';
import * as Moment from 'dayjs';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import DetailDialog from './detail/detailDialog.vue';

const COMMON_COLUMNS = [
  {
    title: '询价信息',
    dataIndex: 'inquireDesc',
    key: 'inquireDesc',
    width: 180,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '业务类型',
    dataIndex: 'businessTypeDesc',
    key: 'businessTypeDesc',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '单据状态',
    dataIndex: 'inquireTenantStatusDesc',
    key: 'inquireTenantStatusDesc',
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: 'inquireTenantStatusDesc' },
  },
  {
    title: '是否入围',
    dataIndex: 'enteredFlag',
    key: 'enteredFlag',
    width: 120,
    ellipsis: true,
    customRender: (text) => {
      return text === true ? '是' : text === false ? '否' : '--';
    },
  },
  {
    title: '是否中标',
    dataIndex: 'winTheBidFlag',
    key: 'winTheBidFlag',
    width: 160,
    ellipsis: true,
    customRender: (text) => {
      return text === true ? '是' : text === false ? '否' : '--';
    },
  },
  {
    title: '商机/询价单号',
    dataIndex: 'code',
    key: 'code',
    width: 160,
    ellipsis: true,
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '询单来源',
    dataIndex: 'releaseSource',
    key: 'releaseSource',
    width: 160,
    ellipsis: true,
    customRender: (text, row) => {
      return text === 1 ? '平台询单' : text === 2 || row.zgyFlag ? '资管易询单' : '--';
    },
  },
  {
    title: '询价单位/个人',
    dataIndex: 'inquirePriceParty',
    key: 'inquirePriceParty',
    width: 200,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '工单',
    dataIndex: 'workOrderCode',
    key: 'workOrderCode',
    width: 160,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '申请人',
    dataIndex: 'createUserName',
    key: 'createUserName',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '接单人',
    dataIndex: 'taskOrderUserName',
    key: 'taskOrderUserName',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 160,
    ellipsis: true,
    customRender: (text) => {
      return text ? Moment(text).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
  },
  {
    title: '紧急程度',
    dataIndex: 'emergencyLevelDesc',
    key: 'emergencyLevelDesc',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
];

export default {
  name: 'InqueryformList',
  components: {
    xzTable,
    XzTagGroup,
    draggable,
    DetailDialog,
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    operationPermission () {
      return this.$store.state.Auth
      && (this.$store.state.Auth.actionPermissionMap['gongxutong:inquire_price:customer_inquire:quote']
      || this.$store.state.Auth.actionPermissionMap['gongxutong:inquire_price:customer_inquire:reject']
      || this.$store.state.Auth.actionPermissionMap['gongxutong:inquire_price:customer_inquire:transfer']
      || this.$store.state.Auth.actionPermissionMap['gongxutong:inquire_price:customer_inquire:confirm_quote']);
    },
    detailPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.actionPermissionMap['gongxutong:inquire_price:customer_inquire:detail'];
    },
    toolsAttrs () {
      return {
        refresh: true,
        zoom: true,
        fullScreen: this.isFullScreen,
        advancedSearch: true,
        custom: true,
      };
    },
    statusWithAll () {
      return [
        {
          label: '全部',
          value: '',
        },
        {
          label: `待接单(${this.countObj?.waitTaskOrderCount || 0})`,
          value: 'WAIT_TASK_ORDER',
        },
        {
          label: `待报价(${this.countObj?.waitQuotePriceCount || 0})`,
          value: 'WAIT_QUOTE_PRICE',
        },
        {
          label: `已报价(${this.countObj?.quotedCount || 0})`,
          value: 'QUOTED',
        },
        {
          label: `议价中(${this.countObj?.negotiatingCount || 0})`,
          value: 'NEGOTIATING',
        },
        {
          label: '已入围',
          value: 'ENTERED',
        },
        {
          label: '已中标',
          value: 'WINTHEBID',
        },
        {
          label: '已拒单',
          value: 'REJECTED',
        },
        {
          label: '已关单',
          value: 'CLOSED',
        },
      ];
    },
  },
  watch: {
    drawerVisible (v) {
      if (v) {
        this.form = { ...this.form, ...this.outform };
      }
    },
  },
  data () {
    return {
      columnsLists: [
        ...COMMON_COLUMNS,
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' },
        }],
      data: [],
      outform: {
        status: '',
        code: '',
      },
      form: {
        status: '',
        code: '',
        businessType: '',
        inquirePriceParty: '',
        createUserName: '',
        taskOrderUserName: '',
        createTimeStart: '',
        createTimeEnd: '',
        emergencyLevel: '',
        workOrderCode: '',
      },
      total: 0,
      searchAttrs: {
        show: true,
        placeholder: '请输入询单号',
        data: 'code',
      },
      drawerVisible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      scrollHeight: 600,
      isFullScreen: false,
      showAttribute: false,
      attrLists: [],
      lockLists: [],
      attrPropLists: [],
      lockPropLists: [],
      countObj: {},
      dialogVisible: false,
      currentRow: {},
    };
  },
  methods: {
    handleView (record) {
      if (this.detailPermission) {
        this.currentRow = record;
        this.dialogVisible = true;
        if (record.inquireTenantStatus === 'NEGOTIATING') {
          this.$nextTick(() => {
            this.$refs.DetailDialogRef.activeName = 'price';
          });
        }
      }
    },
    handleEmpty () {
      this.form = this.$options.data().form;
    },
    handleReset () {
      this.outform = this.$options.data().outform;
      this.form = this.$options.data().form;
      this.page.pageNum = 1;
      this.page.pageSize = 10;

      this.handleSearch();
    },
    handleAdvancedQuery () {
      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleSizeChange (_, size) {
      this.page.pageSize = size;

      this.handleSearch();
    },
    handleNumChange (num) {
      this.page.pageNum = num;

      this.handleSearch();
    },
    async handleSearch () {
      this.data = [];
      const params = {
        enteredFlag: undefined,
        winTheBidFlag: undefined,
        ...this.outform,
        ...this.form,
        ...this.page,
        statuses: [],
        findWorkOrderFlag: true,
        orderBy: [{
          orderByProperty: 'createTime',
          orderByType: 'DESC',
        }],
      };

      if (params.status === 'ENTERED') {
        params.enteredFlag = true;
      } else if (params.status === 'WINTHEBID') {
        params.winTheBidFlag = true;
      } else {
        params.statuses = params.status ? [params.status] : [];
      }

      delete params.status;

      const { body } = await inquirePricePageList(params);

      this.data = (body?.list || []).map(i => ({
        ...i,
        workOrderCode: i.workOrder ? i.workOrder.code : '',
      }));

      this.total = (body?.total || 0) * 1;

      if (this.drawerVisible) {
        this.outform = {
          status: this.form.status,
          code: this.form.code,
        };

        this.drawerVisible = false;
      }
    },
    handleOutChange (key, o) {
      if (key) {
        this.outform[key] = o.value;
        this.form[key] = o.value;
      }

      this.form.code = this.outform.code;

      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleFullScreen (flag) {
      this.isFullScreen = flag;
    },
    handleSort () {
      this.attrLists = this.attrPropLists;
      this.lockLists = this.lockPropLists;
      this.showAttribute = true;
    },
    handleResetCancel () {
      let columnLists = JSON.parse(JSON.stringify(COMMON_COLUMNS));
      let attrLists = columnLists.map((i, index) => ({
        fieldKey: i.key,
        fieldName: i.title,
        businessType: 'customerInquiryForm',
        isRegular: false,
        isShow: true,
        sort: index + 1,
      }));
      this.attrLists = attrLists;
      this.lockLists = [];
    },
    handleSave () {
      let lists = [
        ...this.lockLists,
        ...this.attrLists,
      ].map((i, index) => ({
        ...i,
        sort: index + 1,
      }));
      addFiledRule(lists).then(() => {
        this.getRuleLists(lists);
        this.showAttribute = false;
      });
    },
    // 这里需要处理table
    getColumnsLists (lists) {
      let showIdLists = lists.filter(i => i.isShow).map(i => i.fieldKey);
      let fixedIdLists = lists.filter(i => i.isShow && i.isRegular).map(i => i.fieldKey);

      let currentLists = COMMON_COLUMNS.filter(i => showIdLists.includes(i.dataIndex)).map(i => ({
        ...i,
        fixed: fixedIdLists.includes(i.dataIndex) ? 'left' : '',
      }));
      let tempLists = [];
      for (let i = 0; i < showIdLists.length; i++) {
        const info = currentLists.find(j => j.key === showIdLists[i]);
        tempLists.push(info);
      }

      const columnLists = [...tempLists, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: 150,
        scopedSlots: { customRender: 'action' },
      }];

      this.$nextTick(() => {
        this.purchaseColumns = columnLists;
      });
    },
    handleClickLock (item, index) {
      if (item.isRegular) {
        item.isRegular = false;
        this.lockLists.splice(index, 1);
        this.attrLists.push(item);
      } else {
        item.isRegular = true;
        this.attrLists.splice(index, 1);
        this.lockLists.push(item);
      }
    },
    getRuleLists () {
      getFiledRuleLists({
        businessType: 'customerInquiryForm',
      }).then(({ body }) => {
        let currentLists = body || [];
        currentLists = JSON.parse(JSON.stringify(currentLists));

        if (currentLists && currentLists.length) {
          this.attrLists = currentLists.filter(i => !i.isRegular);
          this.lockLists = currentLists.filter(i => i.isRegular);
          this.getColumnsLists(currentLists);
        } else {
          let columnLists = [...COMMON_COLUMNS, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 150,
            scopedSlots: { customRender: 'action' },
          }];

          this.columns = columnLists;

          // 这里需要根据 columnLists 获取需要处理的数据的值
          let lockLists = columnLists.filter(i => i.fixed && i.title !== '操作').map((i, index) => ({
            fieldKey: i.key,
            fieldName: i.title,
            businessType: 'customerInquiryForm',
            isRegular: false,
            isShow: true,
            sort: index + 1,
          }));
          let attrLists = columnLists.filter((i) => !i.fixed).map((i, index) => ({
            fieldKey: i.key,
            fieldName: i.title,
            businessType: 'customerInquiryForm',
            isRegular: false,
            isShow: true,
            sort: lockLists.length + index + 1,
          }));

          this.attrLists = attrLists;
          this.lockLists = lockLists;
        }
        let columnLists = currentLists && currentLists.length ? currentLists : [...COMMON_COLUMNS];
        this.purchaseColumns = columnLists;

        // 这里需要拼接字段属性
        this.attrPropLists = JSON.parse(JSON.stringify(this.attrLists));
        this.lockPropLists = JSON.parse(JSON.stringify(this.lockLists));
      });
    },
    async getCount () {
      const { body } = await inquirePriceCount();

      this.countObj = body;
    },
    handleInquiryform (record) {
      this.$router.push({
        name: 'CUSTOMER_INQUIRY_HANDLE',
        query: {
          id: record.id,
          type: record.businessType,
          toTab: record.inquireTenantStatus === 'NEGOTIATING' ? 'price' : null,
        },
      });
    },
  },
  created () {
    this.getRuleLists();
  },
  mounted () {
    this.getCount();
    this.handleSearch();
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 8px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .c_group {
        position: relative;

        .title {
          font-size: 14px;
          font-weight: 600;
          padding-right: 2em;
        }

        .c_help {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .table-wrapper {
        padding: 16px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper {
          border-width: 0;
          background-color: #F2F4F7;

          &.active-radio {
            border-color: #E2F3FE;
            background-color: #E2F3FE;
            color: #237FFA;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .c_tag {
          @include tag_round();
          margin-right: 0.2em;
        }

        .wait_task_order {
          background-color: #FEFAE0;
          color: #FAB007;

          &:before {
            color: #FAB007;
          }
        }

        .wait_quote_price {
          background-color: #FEFAE0;
          color: #FAB007;

          &:before {
            color: #FAB007;
          }
        }

        .quoted {
          background-color: #E5FEEB;
          color: #14CA64;

          &:before {
            color: #14CA64;
          }
        }

        .negotiating {
          background-color: #E2F3FE;
          color: #237FFA;

          &:before {
            color: #237FFA;
          }
        }

        .entered {
          background-color: #E5FEEB;
          color: #14CA64;

          &:before {
            color: #14CA64;
          }
        }

        .rejected {
          background-color: #FEE9E5;
          color: #FF5959;

          &:before {
            color: #FF5959;
          }
        }

        .closed {
          background-color: #F2F4F7;
          color: #1F2733;

          &:before {
            color: #1F2733;
          }
        }

        .code {
          color: #237FFA;
          cursor: pointer;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-wrapper.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  z-index: 100;
  background: #FFFFFF;
}

.text-right {
  text-align: right;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
  margin-top: 6px;
}

.top {
  display: flex;
  padding: 6px 0;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #DFE2E8;
  margin-bottom: 10px;
  font-family: PingFang SC;
  font-size: 16px;
  color: #1F2733 100%;
  letter-spacing: 0px;
  opacity: 1;

  span {
    flex: 1;
    height: 24px;
    line-height: 24px;
  }

  time {
    cursor: pointer;
  }
}

.list-item {
  display: flex;
  cursor: move;
  height: 40px;
  padding: 4px 8px;

  .el-checkbox__label {
    height: 32px;
    line-height: 32px;

  }

  .labelContainer {
    flex: 1;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
  }
}

.noFixedItem {
  font-family: PingFang SC;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0px;
  line-height: 0px;
  opacity: 1;
  height: 40px;
  line-height: 32px;
  padding: 4px 8px;
  list-style: none;
}

/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background: rgb(35, 127, 250);
  border-color: rgb(35, 127, 250);
  opacity: 0.4;
  color: #FFFFFF;
}

/deep/.ant-table-body::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

/deep/ .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px #C9CED6;
  background: #C9CED6;
}

//鼠标移入样式
/deep/ .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #C9CED6;
}

.advanced-search {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

/deep/.ant-drawer-body {
  padding: 16px;
}
</style>
